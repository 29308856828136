import React from 'react';
import { graphql } from 'gatsby';
import ArticlesLayout from '../components/PagesLayout/articles';

const Articles = ({ data }) => {
  return <ArticlesLayout data={data} />;
};

export default Articles;

export const pageQuery = graphql`
         query AllArticlesQuery($locale: String!) {
           articles: prismicArticlesSection(lang: { eq: $locale }) {
             data {
               title {
                 text
               }
               load_more_button_label {
                 text
               }
               number_of_articles_visible
               number_of_articles_to_load
               other_articles {
                 text
               }
             }
           }
           articlesContent: allPrismicArticleIndividual(
             filter: { lang: { eq: $locale } }
           ) {
             edges {
               node {
                 uid
                 lang
                 data {
                   title {
                     text
                   }
                   top_priority
                   photo_vertical {
                     url
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_vertical {
                     url
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     url
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     url
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   industry_selector_fr
                   industry_selector_eng
                   highlight_on_homepage
                   excerpt {
                     html
                   }
                   date(formatString: "")
                   countries {
                     country
                   }
                   body {
                     text
                   }
                   tags {
                     text
                   }
                 }
               }
             }
           }
         }
       `;
