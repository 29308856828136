import React from 'react';
import PropTypes from 'prop-types';
import { getQuery } from '../../functions/getQuery';
import HeroArticle from '../PageSections/ArticleSection/HeroArticle';
import HeadingContainer from '../PageSections/HeadingContainer/HeadingContainer';
import ProjectTiles from '../ProjectTiles/ProjectTiles';
import { ArticlePageContainer } from './articles.css';
import Transition from '../Transition/Transition';
import Fade from 'react-reveal/Fade';
import Footer from '../Footer/Footer';
import moment from 'moment';

const ArticlesLayout = ({ data }) => {
  const articlesJsonMain = getQuery(['articles', 'data'], data);
  const allArticlesJson = getQuery(['articlesContent', 'edges'], data);

  const HighlightedArticle = allArticlesJson.find(({ node: article }) =>
    article.data.top_priority
      ? article.data.top_priority
      : console.log('nothing to display on top Priority Article')
  );

  const finalQueryArticles = allArticlesJson
    .filter((article) => article.node.uid !== HighlightedArticle.node.uid)
    .sort((a, b) => {
      if (moment(a.node.data.date) > moment(b.node.data.date)) {
        return -1;
      } else {
        return 1;
      }
    });

  return (
    <Transition>
      <ArticlePageContainer>
        <HeadingContainer hideBorder title={articlesJsonMain.title.text} />
        {HighlightedArticle ? (
          <Fade bottom distance='30px' delay={500}>
            <HeroArticle query={HighlightedArticle} backgroundColor='black' />
          </Fade>
        ) : (
          ''
        )}
        <ProjectTiles
          className='Articles__ProjectTiles'
          query={finalQueryArticles}
          id='articles'
          numberOfVisibleArticles={articlesJsonMain.number_of_articles_visible}
          numberOfArticlesToLoad={articlesJsonMain.number_of_articles_to_load}
          loadMore={articlesJsonMain.load_more_button_label.text}
        />
      </ArticlePageContainer>
      <Footer />
    </Transition>
  );
};

ArticlesLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticlesLayout;
